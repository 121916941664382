import { ProductSnap } from "@launerlondon/products";
import {
	HeroBanner,
	ProductListItem,
	useBreakpoint,
} from "@launerlondon/shop-components";
import { AppLoaderData, routes } from "@launerlondon/shop-router";
import { Fragment, useEffect, useRef, useState } from "react";
import { useIsVisible } from "react-is-visible";
import { useRouteLoaderData } from "react-router-dom";
import { twJoin, twMerge } from "tailwind-merge";
import { trackViewItemList } from "../lib/analytics";
import { getGridDividerIndex } from "@launerlondon/shop-utils";
import { useProductListParams } from "@launerlondon/shop-hooks";

type Props = {
	slug?: string;
	title?: string;
	titleTag?: "h1" | "h2";
	products: ProductSnap[];
	headerClassName?: string;
	divider?: React.ReactElement;
};

function getBigTileIndexes(
	total: number,
	tiles: number,
	rowItems: number,
	tileSpan = 2,
) {
	// FIXME doesn't currently work on different tileSpans, only 2
	let indexes: Array<number> = [];
	const oddCols = rowItems % 2 !== 0;
	const jump = Math.floor((total - rowItems * 2) / Math.max(tiles, 2));
	if (total < rowItems * 2 || jump === 0) return indexes;
	let reverse = 0;

	for (let n = 0; n < tiles; n++) {
		reverse = reverse ? 0 : 1;
		const k = (n + 1) * jump;
		let step = k - (k % rowItems);
		if (oddCols) {
			/*
			 * for odd column per row, compensate variation per line with (n)
			 * on reverse should diff rowItems - tileSpan
			 */
			step += n;
			step = reverse ? step + rowItems - tileSpan : step;
		} else {
			step = reverse ? step + tileSpan : step;
		}
		indexes.push(step);
	}
	return indexes;
}

const ProductListSection: React.FC<Props> = (props) => {
	const [gridCols, setGridCols] = useState(0);
	const [bigTileIndexes, setBigTileIndexes] = useState<number[]>([]);
	const { listViewMode } = useRouteLoaderData(routes.home) as AppLoaderData;
	const ref = useRef<HTMLElement>(null);
	const isVisibile = useIsVisible(ref);
	const breakpoint = useBreakpoint();
	const { byStyle } = useProductListParams();
	const tiles = HeroBanner.useSection(props.slug)?.tiles;
	const TitleTag = props.titleTag || "h2";

	useEffect(() => {
		const t = listViewMode === "tight";
		const cols = {
			xs: t ? 2 : 1,
			sm: t ? 2 : 1,
			md: t ? 3 : 2,
			lg: t ? 4 : 2,
			xl: t ? 5 : 3,
			"2xl": t ? 5 : 3,
		};
		setGridCols(cols[breakpoint]);
	}, [breakpoint, listViewMode]);

	useEffect(() => {
		if (byStyle) {
			setBigTileIndexes([]);
			return;
		}
		setBigTileIndexes(
			getBigTileIndexes(
				props.products.length,
				tiles?.length || 0,
				gridCols,
			),
		);
	}, [gridCols, byStyle, props.products.length, tiles?.length]);

	useEffect(
		() =>
			void (isVisibile && trackViewItemList(props.products, props.title)),
		[isVisibile, props.title, props.products],
	);

	let tileIndex = -1;
	return (
		<section ref={ref} className="container pb-4 text-gray-800">
			{props.title ? (
				<TitleTag
					className={twMerge(
						TitleTag === "h1" ? "ln-title" : "ln-subtitle",
						"top-0 z-20 lg:sticky",
						"bg-white/95",
						"my-10 py-4 text-center",
						props.headerClassName,
					)}
				>
					{props.title}
				</TitleTag>
			) : (
				<div className="h-10" />
			)}
			<div
				className={twJoin(
					"grid gap-x-2 gap-y-10 py-2 md:gap-x-4",
					listViewMode === "tight"
						? "grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
						: "grid-cols-1 md:grid-cols-2 xl:grid-cols-3",
				)}
			>
				{props.products.map((p, i, arr) => {
					const bigTile = bigTileIndexes.includes(i);
					if (bigTile) tileIndex++;

					let divide =
						!bigTileIndexes.length &&
						props.divider &&
						arr.length > 20 &&
						getGridDividerIndex(arr.length, gridCols) === i;
					return (
						<Fragment key={i}>
							{divide && (
								<div
									className={twJoin(
										listViewMode === "tight"
											? "col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-5"
											: "col-span-1 md:col-span-2 xl:col-span-3",
										"empty:hidden",
									)}
								>
									{props.divider}
								</div>
							)}
							{bigTile && (
								<div className="lg:col-span-2 lg:row-span-2">
									<HeroBanner.BigTile
										section={props.slug}
										index={tileIndex}
									/>
								</div>
							)}
							<ProductListItem product={p} />
						</Fragment>
					);
				})}
			</div>
		</section>
	);
};

export default ProductListSection;
